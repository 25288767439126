import React, { useState } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core CSS
import "primeicons/primeicons.css"; // Icons

const App = () => {
  const [date, setDate] = useState("2025-01-24");
  const [latitude, setLatitude] = useState(17.38333);
  const [longitude, setLongitude] = useState(78.4666);
  const [data, setData] = useState(null);

  const fetchPanchang = async () => {
    const [year, month, day] = date.split("-").map(Number);

    const requestData = {
      year,
      month,
      date: day,
      hours: 6,
      minutes: 0,
      seconds: 0,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      timezone: 5.5,
      config: {
        observation_point: "topocentric",
        ayanamsha: "lahiri",
      },
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "kTunRBBFRl8JBCaynM7c8ai0f1GHsnkrCqILUZN1",
      },
    };

    try {
      const response = await axios.post(
        "https://json.freeastrologyapi.com/complete-panchang",
        requestData,
        options
      );
      const parsedData = JSON.parse(response.data.output);
      setData(parsedData);
    } catch (error) {
      console.error("Error fetching Panchang data:", error);
    }
  };

  const teluguLabels = {
    sun_rise: "సూర్యోదయం",
    sun_set: "సూర్యాస్తమయం",
    weekday_name: "వారం పేరు",
    tithi_name: "తిథి పేరు",
    tithi_completes_at: "తిథి పూర్తయ్యే సమయం",
    nakshatra_name: "నక్షత్రం పేరు",
    nakshatra_ends_at: "నక్షత్రం పూర్తయ్యే సమయం",
    aayanam: "అయనం",
    ritu_name: "ఋతువు పేరు",
    saka_salivahana_year_name : 'సంవత్సరం'
  };

  return (
    <div className="p-4">
      <h1>పంచాంగం వివరాలు</h1>
      <div className="grid">
        <div className="col-4">
          <label>తేదీ:</label>
          <InputText
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="col-4">
          <label>అక్షాంశం (Latitude):</label>
          <InputText
            type="number"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
          />
        </div>
        <div className="col-4">
          <label>రేఖాంశం (Longitude):</label>
          <InputText
            type="number"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
          />
        </div>
        <div className="col-12 mt-3">
          <Button
            label="పంచాంగం పొందండి"
            onClick={fetchPanchang}
            className="p-button-success"
          />
        </div>
      </div>
      {data && (
        <div className="grid mt-4">
          <div className="col-12">
            <h2>పంచాంగం</h2>
          </div>
          <div className="col-6">
            <label>{teluguLabels.sun_rise}:</label>
            <InputText value={data.sun_rise} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.sun_set}:</label>
            <InputText value={data.sun_set} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.weekday_name}:</label>
            <InputText value={data.weekday.weekday_name} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.tithi_name}:</label>
            <InputText value={data.tithi.name} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.tithi_completes_at}:</label>
            <InputText value={data.tithi.completes_at} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.nakshatra_name}:</label>
            <InputText value={data.nakshatra.name} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.nakshatra_ends_at}:</label>
            <InputText value={data.nakshatra.ends_at} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.aayanam}:</label>
            <InputText value={data.aayanam} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.ritu_name}:</label>
            <InputText value={data.ritu.name} readOnly />
          </div>
          <div className="col-6">
            <label>{teluguLabels.saka_salivahana_year_name}:</label>
            <InputText value={data.year.saka_salivahana_year_name} readOnly />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
// {
//   "sun_rise": "6:49:28",
//   "sun_set": "18:6:4",
//   "weekday": {
//     "weekday_number": 5,
//     "weekday_name": "Friday",
//     "vedic_weekday_number": 5,
//     "vedic_weekday_name": "Friday"
//   },
//   "lunar_month": {
//     "lunar_month_number": 10,
//     "lunar_month_name": "Pushyam",
//     "lunar_month_full_name": "Pushyam",
//     "adhika": 0,
//     "nija": 0,
//     "kshaya": 0
//   },
//   "ritu": {
//     "number": 6,
//     "name": "Sisira (Winter & Fall)"
//   },
//   "aayanam": "Uttarayanam",
//   "tithi": {
//     "number": 25,
//     "name": "Dashami",
//     "paksha": "krishna",
//     "completes_at": "2025-01-24 18:03:57",
//     "left_precentage": 25.82
//   },
//   "nakshatra": {
//     "number": 17,
//     "name": "Anuradha",
//     "starts_at": "2025-01-24 03:58:28",
//     "ends_at": "2025-01-25 05:46:59",
//     "left_percentage": 70.98673459557733
//   },
//   "yoga": {
//     "1": {
//       "number": 11,
//       "name": "Vriddhi",
//       "completion": "2025-01-25 03:52:01",
//       "yoga_left_percentage": 68.73695079576035
//     },
//     "2": {
//       "number": 12,
//       "name": "Dhruva",
//       "completion": "2025-01-26 03:14:30"
//     }
//   },
//   "karana": {
//     "1": {
//       "number": 50,
//       "name": "Vishti",
//       "karana_left_percentage": 51.63670754532177,
//       "completion": "2025-01-24 18:03:59"
//     },
//     "2": {
//       "number": 51,
//       "name": "Bava",
//       "completion": "2025-01-25 06:37:41"
//     },
//     "3": {
//       "number": 52,
//       "name": "Balava",
//       "completion": "2025-01-25 19:00:28"
//     }
//   },
//   "year": {
//     "status": "success",
//     "timestamp": "2025-03-29 15:12:34",
//     "saka_salivahana_number": 1946,
//     "saka_salivahana_name_number": 38,
//     "saka_salivahana_year_name": "Krodhi",
//     "vikram_chaitradi_number": 2081,
//     "vikram_chaitradi_name_number": 52,
//     "vikram_chaitradi_year_name": "Kaala Yukti"
//   }
// }
